<template>
  <div>
    <PageHeader />
    <b-card v-if="isFetchingLottoGame" class="text-center">
      <b-spinner />
    </b-card>
    <b-card v-else>
      <h4>{{ lottoGame.name }}</h4>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form id="openForm" @submit.prevent="handleSubmit(submitOpenNewRoundForm)">
        <b-row>
          <b-col sm="6">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group
                :invalid-feedback="errors[0]"
                label="เปิดรับแทง"
                label-cols-lg="3"
              >
                <DateTimePicker
                    v-model="openDate"
                    :class="{ 'is-invalid': errors[0] }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group
                :invalid-feedback="errors[0]"
                label="ปิดรับแทง"
                label-cols-lg="3"
              >
                <DateTimePicker
                    v-model="closeDate"
                    :class="{ 'is-invalid': errors[0] }"
                    :disabled="!openDate"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group
                  :invalid-feedback="errors[0]"
                  label="คืนโพยได้ถึง"
                  label-cols-lg="3"
              >
                <DateTimePicker
                    v-model="closeRefundDate"
                    :class="{ 'is-invalid': errors[0] }"
                    :disabled="!closeDate"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group
                :invalid-feedback="errors[0]"
                label="วันออกผล"
                label-cols-lg="3"
              >
                <DateTimePicker
                    v-model="resultDate"
                    :class="{ 'is-invalid': errors[0] }"
                    :disabled="!closeDate"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <hr>
        <div class="text-right">
          <b-button :to="lottoListUrl" class="mr-3" variant="white">
            <i class="uil uil-arrow-left"></i>
            {{ $t('buttons.back') }}
          </b-button>
          <b-button class="mr-3" type="submit" variant="primary">
            <i class="uil uil-check"></i>
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {LottoGameRoundStatusEnum, LottoGameTypeEnum} from '@src/constants/lotto';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  page: {
    title: 'เปิดรอบใหม่',
  },
  data() {
    return {
      openDate: null,
      closeDate: null,
      resultDate: null,
      closeRefundDate: null,
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoGame: (state) => state.lotto.isFetchingLottoGame,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'lottoGame']),
    lottoGameId() {
      return this.$route.params.id
    },
    lottoListUrl() {
      const type = this.$route.query.type || LottoGameTypeEnum.THAI_GOVERNMENT
      return `/lotto/list?type=${type}`
    }
  },
  async created() {
    if (this.lottoGameId) {
      await this.fetchData()
    }
    if(this.lottoGame) {
      const { lottoGameRound } = this.lottoGame
      if (!lottoGameRound || lottoGameRound.status === LottoGameRoundStatusEnum.DONE) {
        this.openDate = dayjs()
            .set('second', 0)
            .format()
      } else {
        this.openDate = dayjs(lottoGameRound.startBetDatetime).format()
        this.resultDate = dayjs(lottoGameRound.resultDatetime).format()
        this.closeDate = dayjs(lottoGameRound.closeDatetime).format()
        this.closeRefundDate = dayjs(lottoGameRound.closeRefundDatetime).format()
      }
    }

  },
  methods: {
    ...mapActions(['fetchLottoGameById', 'submitOpenNewRound']),
    async fetchData() {
      await this.fetchLottoGameById(this.lottoGameId)
    },
    submitOpenNewRoundForm() {
      if(
          dayjs(this.openDate).isAfter(this.closeDate) ||
          dayjs(this.closeDate).isAfter(this.resultDate) ||
          dayjs(this.closeRefundDate).isAfter(this.closeDate)
      ) {
        this.$bvToast.toast('เวลาที่กรอกไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง', {
          variant: 'warning',
          title: 'ผิดพลาด',
        })
        return
      }
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then(async (confirm) => {
          if (confirm) {
            const gameDate = dayjs(this.resultDate).format('DD/MM/YYYY')
            await this.submitOpenNewRound({
              id: this.lottoGame.id,
              payload: {
                name: `${this.lottoGame.name} ${gameDate}`,
                gameDate: this.resultDate,
                startBetDate: this.openDate,
                closeRefundDate: this.closeRefundDate,
                closeDate: this.closeDate,
                resultDate: this.resultDate,
              },
            })

            this.$router.push(this.lottoListUrl)
          }
        })
    },
  },
}
</script>
